import { ShareIcon } from "@heroicons/react/24/solid";
import React from "react";
import { share, ShareOptions } from "utils/share";
import LinkButton from "./LinkButton";

interface ShareButtonProps {
    shareOptions: ShareOptions;
}

const ShareButton: React.FC<ShareButtonProps> = ({ shareOptions }) => {
    async function handleClick() {
        await share(shareOptions);
    }

    return (
        <LinkButton.Secondary title="Share" onClick={handleClick}>
            <ShareIcon width={24} />
        </LinkButton.Secondary>
    );
};

export default ShareButton;
